.ant-layout-sider-children {
  ::-webkit-scrollbar {
    width: 6px !important;
  }

  /* stylelint-disable-next-line no-duplicate-selectors */
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  ::-webkit-scrollbar-thumb {
    background: #ccc;
  }

  ::-webkit-scrollbar-track {
    background-color: #e6e5e5;
  }
}
