.notification {

  &__wrapper {
    width: 100%;

    &__list {
      margin: 0 -14px;
    }

    &-gray {
      background-color: rgba(65, 116, 139, 0.1);
    }
  }

  &__item {

    &__icon {
      color: #2597FF;
      transform: scale(1.75);
      align-self: start;
      padding: 5px 15px 5px 5px;
    }
    &__title {
      color: #333;
      font-size: 1.15em;
    }
    &__message {
      color: #666;
    }
    &__time {
      font-size: 0.95em;
      font-style: italic;
    }
  }
}
